@import '../fotns/webFonts/css/fontiran.css';
@import './tailwind.output.css';

body {
  text-align: right;
  direction: rtl;
  font-family: 'IRANSans' !important;
  background-color: #f5f5f5 !important;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
tspan,
button {
  font-family: 'IRANSans' !important;
}

iframe {
  display: none;
}

.text-right .DatePicker__input {
  text-align: right;
}

.search-input {
  min-width: 300px;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .search-input {
    min-width: 100%;
  }
}

.select_product {
  width: 40%;
}
@media only screen and (max-width: 768px) {
  .select_product {
    min-width: 62%;
  }
}

.Collapsible__trigger {
  padding: 5px;
  border-radius: 5px;
  display: block;
  border: 1px dashed;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 8px;
  border: 8px solid #7e3af2;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #7e3af2 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
